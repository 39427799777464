import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { graphql } from "gatsby"
import React from "react"

import Feature from "../components/feature"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Certificate from "../images/svg/certificate.svg"

interface Props {
  title: string
  heading: string
  subheading: string
}

export const AboutUsPageTemplate = (props: Props) => {
  return (
    <Container>
      <SEO
        title="About Us"
        description="Find out more information about The Institute of Beauty. We offers a wide range of ITEC and CIBTAC professional qualifications in the areas of Beauty Therapy, Massage and Complementary Therapies."
      />
      <Grid
        container
        style={{ marginTop: "60px", textAlign: "center", marginBottom: "40px" }}
      >
        <Grid item xs={12}>
          <h1>About Us</h1>
          <h2 style={{ fontWeight: 700 }}>
            Your journey to a new career starts here
          </h2>
        </Grid>
      </Grid>
      <Feature
        title="Who we are"
        subTitle="The Institute of Beauty is one of Ireland’s leading beauty and complementary therapies training colleges where excellence is standard"
        body={[
          "Based in Waterford, The Institute of Beauty offers a wide range of full and part-time courses leading to professional qualifications recognised world-wide in the areas of Beauty Therapy, Massage and Complementary Therapies.",
          "The Institute of Beauty was founded by Caroline Holohan, a highly experienced beauty therapist and tutor. Renowned for its small class sizes and individual attention, The Institute of Beauty places an emphasis on the practical aspects of learning as well as providing exceptional theory-based tuition from a hand-picked team of industry experts.",
          "The Laser & Light Treatment classes will be held at The Clinic Beauty Salon in Kilkenny where the students will have full access to a “Cynosure” laser machine.",
        ]}
        link="https://theclinicofbeauty.ie/"
        FeatureSVG={Certificate}
        position="left"
        featurePadding="0 60px"
      />
    </Container>
  )
}

interface AboutUsPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        [key: string]: any
      }
    }
  }
}

const AboutUsPage = ({ data }: AboutUsPageProps) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutUsPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`

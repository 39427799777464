import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"

const useStyles = makeStyles(() =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
)

interface BodyProps {
  title: string
  subTitle: string
  body: string[]
  position: "left" | "right"
  link: string
}

const Body = (props: BodyProps) => {
  const { title, subTitle, body, position = "left", link } = props
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  let paddingLeft = "0"
  let paddingRight = "0"
  if (!isMobile && !isTablet) {
    paddingLeft = position === "left" ? "100px" : "0"
    paddingRight = position === "left" ? "0" : "100px"
  } else if (!isMobile && isTablet) {
    paddingLeft = "20px"
  }
  return (
    <Grid
      item
      xs={12}
      sm={7}
      md={6}
      style={{
        padding: "0",
        paddingLeft,
        paddingRight,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <h2>{title}</h2>
        <p style={{ fontWeight: 600, color: "#222222" }}>{subTitle}</p>
        {body.map((p, idx) => (
          <p key={`body_${idx}`} style={{ fontWeight: 300 }}>
            {p}
          </p>
        ))}
        {link && (
          <a
            href={link} // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
          >
            {link}
          </a>
        )}
      </div>
    </Grid>
  )
}

interface Props {
  title: string
  subTitle: string
  body: string[]
  position: "left" | "right"
  FeatureSVG: any
  featurePadding?: string
  link?: string
}

const Feature = (props: Props) => {
  const { FeatureSVG, position = "left", featurePadding = "0" } = props
  const classes = useStyles()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Grid
      container
      style={{
        height: "100%",
        marginTop: isTablet ? "80px" : "0",
        marginBottom: "60px",
      }}
    >
      {!isMobile && position === "left" && (
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          className={classes.flexContainer}
          style={{ padding: featurePadding }}
        >
          <FeatureSVG />
        </Grid>
      )}
      <Body {...props} />
      {isMobile && (
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          style={{ padding: "40px 0 0 0" }}
          className={classes.flexContainer}
        >
          <FeatureSVG />
        </Grid>
      )}
      {!isMobile && position === "right" && (
        <Grid item xs={12} sm={5} md={6} className={classes.flexContainer}>
          <FeatureSVG />
        </Grid>
      )}
    </Grid>
  )
}
export default Feature
